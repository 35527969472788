<template>
	<div class="page-web page_regi">
		<!-- <RegiHead :MyLocal="location" @newLocal="location=$event" /> -->
		<div class="regi-bd">
			<div class="regi_name">{{$t('password3')}}</div>
			<!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane :label="$t('wjtab')" name="first">
					<el-form ref="form" :rules="rules" class="regi-from" :model="form">
						<el-form-item prop="phone">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('phone')}}</div>
								<div class="regi_gr_b flex flex-ac">
									<div class="regi_phone flex flex-ac flex-hc">
										<img v-if="countryImageUrl" :src="countryImageUrl" alt="">
										<div v-if="countryImageUrl" class="regi_unit">+</div>
										<el-select v-model="form.country" placeholder="" popper-class="select-drop">
											<el-option v-for="(item,index) in country" :key="index"
												:label="item.areaCode" :value="item.zhName">
												<div class="select-item" @click="countryItem(item.countryImageUrl)">
													<img :src="item.countryImageUrl" alt="">
													<div class="select-item__bd flex_bd">
                            <span>{{location == 'zh_CN' ? item.zhName : item.enName}}</span>
														<span>+{{ item.areaCode }}</span>
													</div>
												</div>
											</el-option>
										</el-select>
									</div>
									<el-input v-model="form.phone" placeholder=""></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="code">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('code')}}</div>
								<div class="regi_gr_b flex flex-ac">
									<el-input v-model="form.code" placeholder=""></el-input>
									<el-button v-if="disabled" class="btn btn_yzm" @click="sendMobile" type="primary">
										{{$t('code2')}}
									</el-button>
									<el-button v-else class="btn btn_yzm" type="primary" :disabled="!disabled">
										{{count}}
									</el-button>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="password">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('wjlabel')}}</div>
								<div class="regi_gr_b">
									<el-input v-model="form.password" placeholder="" :type="pass?'password' : 'text'">
									</el-input>
									<div class="regi_eye" @click="eye">
										<i class="iconfont icon-eye" v-if="Eye"></i>
										<i class="iconfont icon-eye-close" v-else></i>
									</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="checkpassword">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('password4')}}</div>
								<div class="regi_gr_b">
									<el-input v-model="form.checkpassword" placeholder=""
										:type="pass1?'password' : 'text'">
									</el-input>
									<div class="regi_eye" @click="eye1">
										<i class="iconfont icon-eye" v-if="Eye1"></i>
										<i class="iconfont icon-eye-close" v-else></i>
									</div>
								</div>
							</div>
						</el-form-item>
						<el-button class="btn" type="primary" @click="submitForm('form')">{{$t('submit')}}</el-button>
					</el-form>
				</el-tab-pane>
				<el-tab-pane :label="$t('wjtab2')" name="second"> -->
					<el-form ref="form2" :rules="rules2" class="regi-from" :model="form2">
						<el-form-item prop="email">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('email2')}}</div>
								<div class="regi_gr_b">
									<el-input autofocus="autofocus" v-model="form2.email" :placeholder="$t('email2')"></el-input>
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="code2">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('chTxt11')}}</div>
								<div class="regi_gr_b flex flex-ac">
									<el-input v-model="form2.code2" :placeholder="$t('chTxt11')">
										<span v-if="disabled2" slot="suffix" class="regi_input_text regi_send"  @click="sendEmail">{{$t('code2')}}</span>
										<span v-else  class="regi_input_text" slot="suffix">{{count2}}</span>
									</el-input>
									<!-- <el-button v-if="disabled2" class="btn btn_yzm" type="primary" @click="sendEmail">
										{{$t('code2')}}
									</el-button>
									<el-button v-else class="btn btn_yzm" type="primary" :disabled="!disabled2">
										{{count2}}
									</el-button> -->
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="password2">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('wjlabel')}}</div>
								<div class="regi_gr_b">
									<el-input v-model="form2.password2" :placeholder="$t('wjlabel')"
										:type="pass2?'password' : 'text'">
									</el-input>
									<!-- <div class="regi_eye" @click="eye2">
										<i class="iconfont icon-eye" v-if="Eye2"></i>
										<i class="iconfont icon-eye-close" v-else></i>
									</div> -->
								</div>
							</div>
						</el-form-item>
						<el-form-item prop="checkpassword2">
							<div class="regi_group">
								<div class="regi_gr_t">{{$t('password4')}}</div>
								<div class="regi_gr_b">
									<el-input v-model="form2.checkpassword2" :placeholder="$t('password4')"
										:type="pass3?'password' : 'text'">
									</el-input>
									<!-- <div class="regi_eye" @click="eye3">
										<i class="iconfont icon-eye" v-if="Eye3"></i>
										<i class="iconfont icon-eye-close" v-else></i>
									</div> -->
								</div>
							</div>
						</el-form-item>
						<el-button size="large" class="btn" type="primary" @click="submitForm2('form2')">{{$t('submit')}}</el-button>
					</el-form>
				<!-- </el-tab-pane>
			</el-tabs> -->
			<!-- 获取滑块验证码图片 -->
			<el-dialog title="安全验证" :visible.sync="dialogVisible" custom-class="custom-dialog2"
				:close-on-click-modal="false" :before-close="handleClose">
				<div id="canvas">
					<img :src="backgroundImage" alt="">
					<VueDragResize ref="dragPlug" :isActive="true" :w="width" :h="height" :parentW="parentW"
						:parentH="parentH" :x="x" :y="y" @dragging="dragging" @dragstop="dragstop"
						:parentLimitation="true" :isResizable="false" axis="x">
						<img :src="sliderImage" class="logoImg">
					</VueDragResize>
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import VueDragResize from 'vue-drag-resize';
	import {
		getCountry,
		checkYZMPic,
		resetMobileCode,
		getYZMPic,
		resetEmailCode,
		checkUsername,
		resetPassword
	} from '@/api/api/user'
	export default {
		components: {
			VueDragResize,
		},
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error(this.$t('wjerr')));
				} else {
					if (this.form.checkpassword !== '') {
						this.$refs.form.validateField('checkpassword');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error(this.$t('confirmpwdtip')));
				} else if (value !== this.form.password) {
					callback(new Error(this.$t('confirmpwderr')));
				} else {
					callback();
				}
			};
			var validatePass3 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error(this.$t('wjerr')));
				} else {
					if (this.form2.checkpassword2 !== '') {
						this.$refs.form2.validateField('checkpassword2');
					}
					callback();
				}
			};
			var validatePass4 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error(this.$t('confirmpwdtip')));
				} else if (value !== this.form2.password2) {
					callback(new Error(this.$t('confirmpwderr')));
				} else {
					callback();
				}
			};
			return {
				activeName: 'second',
				Eye: true,
				Eye1: true,
				Eye2: true,
				Eye3: true,
				pass: true,
				pass1: true,
				pass2: true,
				pass3: true,
				country: [],
				countryImageUrl: "",
				form: {
					country: '美国',
					phone: '',
					code: '',
					password: '',
					checkpassword: ''
				},
				rules: {
					phone: [{
						required: true,
						message: this.$t('logErr'),
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: this.$t('regErr')
					}],
					password: [{
						validator: validatePass,
						trigger: 'blur'
					}, {
						type: 'string',
						min: 6,
						message: this.$t('logErr3'),
						trigger: 'blur',
					}],
					checkpassword: [{
						validator: validatePass2,
						trigger: 'blur'
					}]
				},
				form2: {
					email: '',
					code2: '',
					password2: '',
					checkpassword2: '',
				},
				rules2: {
					email: [{
							required: true,
							message: this.$t('logErr'),
							trigger: 'blur'
						},
						{
							type: 'email',
							message: this.$t('emailerr2'),
							trigger: ['blur', 'change']
						}
					],
					code2: [{
						required: true,
						message: this.$t('regErr')
					}],
					password2: [{
						validator: validatePass3,
						trigger: 'blur'
					}],
					checkpassword2: [{
						validator: validatePass4,
						trigger: 'blur'
					}]
				},
				location: "en_US",
				width: 110,
				height: 360,
				parentW: 590,
				parentH: 360,
				x: 0,
				y: 0,
				backgroundImage: '',
				sliderImage: '',
				dialogVisible: false,
				waitTime: 60,
				count: '60s',
				disabled: true,
				waitTime2: 60,
				count2: '60s',
				disabled2: true,
			};
		},
		created() {
			this.location = localStorage.getItem('lang')
		},
		mounted() {
			this.countryList()
		},
		methods: {
			dragging(object) {
				console.log(object)
			},
			dragstop(object) {
				var code = object.left / (this.parentW - this.width)
				// this.yZMPic()
				if (this.activeName == 'first') {
					checkYZMPic({
						code: code,
						type: 'REGISTER',
						username: this.form.phone
					}).then(res => {
						if (res.code == 0) {
							this.$message({
								message: res.message,
								type: 'success'
							});
							this.dialogVisible = false
							this.$refs.dragPlug.left = 0
							resetMobileCode({
								account: this.form.phone
							}).then(res => {
								if (res.code == 0) {
									this.form.code = ''
								}
							})
						} else {
							this.getPic()
							this.$refs.dragPlug.left = 0
						}
					})
				}
				if (this.activeName == 'second') {
					checkYZMPic({
						code: code,
						type: 'REGISTER',
						username: this.form2.email
					}).then(res => {
						if (res.code == 0) {
							this.$message({
								message: res.message,
								type: 'success'
							});
							this.dialogVisible = false
							this.$refs.dragPlug.left = 0
							resetEmailCode({
								account: this.form2.email,
							}).then(res => {
								if (res.code == 0) {
									this.form2.code2 = ''
								}
							})
						} else {
							this.getPic()
							this.$refs.dragPlug.left = 0
						}
					})
				}
			},
			async getPic() {
				if (this.activeName == 'first') {
					const res = await getYZMPic({
						type: 'REGISTER',
						username: this.form.phone
					})
					if (res.code == 0) {
						this.backgroundImage = res.data.backgroundImage
						this.sliderImage = res.data.sliderImage
					}
				}
				if (this.activeName == 'second') {
					const res = await getYZMPic({
						type: 'REGISTER',
						username: this.form2.email
					})
					if (res.code == 0) {
						this.backgroundImage = res.data.backgroundImage
						this.sliderImage = res.data.sliderImage
					}
				}
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(() => {
						done();
					})
					.catch(() => {});
			},
			countryList() {
				getCountry().then(res => {
					if (res.code == 0) {
						console.log(res)
						this.country = res.data
						this.countryImageUrl = this.country[0].countryImageUrl
					}
				})
			},
			countryItem(url) {
				this.countryImageUrl = url
			},
			sendEmail() {
				this.disabled2 = false
				let clock = window.setInterval(() => {
					this.count2 = this.waitTime2 + 's'
					this.waitTime2--;
					if (this.waitTime2 <= 0) {
						this.waitTime2 = 60;
						this.disabled2 = true
						window.clearInterval(clock);
					}
				}, 1000);
				this.dialogVisible = true;
				this.getPic()
			},
			sendMobile() {
				this.disabled = false
				let clock = window.setInterval(() => {
					this.count = this.waitTime + 's'
					this.waitTime--;
					if (this.waitTime <= 0) {
						this.waitTime = 60;
						this.disabled = true
						window.clearInterval(clock);
					}
				}, 1000);
				this.dialogVisible = true;
				this.getPic()
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
            resetPassword({
              code: this.form.code,
              password: this.form.password,
              account: this.form.phone,
              mode: 0,
            }).then(res => {
              if (res.code == 0) {
                this.$notify({
                  title: this.$t("czTxt9"),
                  message: res.message,
                  type: 'success'
                });
                this.$router.push({
                  path: '/login'
                })
              } else {
                this.$notify.error({
                  title: this.$t("czTxt9"),
                  message: res.message
                });
              }
            })

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			submitForm2(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
            resetPassword({
              code: this.form2.code2,
              account: this.form2.email,
              password: this.form2.password2,
              mode: 1
            }).then(res => {
              if (res.code == 0) {
                if (res.code == 0) {
                  this.$notify({
                    title: this.$t("czTxt9"),
                    message: res.message,
                    type: 'success'
                  });
                  this.$router.push({
                    path: '/login'
                  })
                } else {
                  this.$notify.error({
                    title: this.$t("czTxt9"),
                    message: res.message
                  });
                }
              }
            })
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			eye() {
				this.Eye = !this.Eye
				this.pass = !this.pass
			},
			eye1() {
				this.Eye1 = !this.Eye1
				this.pass1 = !this.pass1
			},
			eye2() {
				this.Eye2 = !this.Eye2
				this.pass2 = !this.pass2
			},
			eye3() {
				this.Eye3 = !this.Eye3
				this.pass3 = !this.pass3
			}
		}
	}
</script>
